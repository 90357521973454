import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Spinner
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/DarkNavbar";
import TransparentFooter from "components/Footers/TransparentFooter";

import toast from "react-hot-toast";

import splitbee from '@splitbee/web';

function Sections({ match }) {
  const [firstFocus, setFirstFocus] = useState(false);
  const [lastFocus, setLastFocus] = useState(false);
  const [formName, setFormName] = useState('')
  const [formEmail, setFormEmail] = useState('')
  const [formMsg, setFormMsg] = useState('')
  const [sectionText, setSectionText] = useState('')
  const [sectionId, setSectionId] = useState()
  const [sectionContactKey, setSectionContactKey] = useState()
  const [isLoaded, setIsLoaded] = useState(false)

  const sendMail = async () => {
    return new Promise((resolve, reject) => {

      const data = {
        accessKey: sectionContactKey,
        name: formName,
        email: formEmail,
        $section: sectionId,
        message: formMsg,
        subject: 'Message - Millenium',
        replyTo: '@',

      };
      console.log(data);

      fetch('https://api.staticforms.xyz/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            console.log('success response');
          } else {
            reject()
          }
          console.log('Success:', data);
          resolve()
        })
        .catch((error) => {
          console.error('Error:', error);
          reject()
        });
    })
  }
  const getSectionName = (sectionId) => {
    switch (sectionId) {
      case "tennisdetable":
        return "Tennis de Table"
      case "expression":
        return "Expression"
      case "pilate":
        return "Pilate"
      case "gym":
        return "Gym-Tonic"
      case "collection":
        return "Multi-Collection"
      case "3age":
        return "3ème Age"
      case "chorale":
        return "Chorale"
      default:
        return
    }
  }

  const fetchData = async (sectionId) => {
    var data = await fetch(`https://millenium-marles.firebaseio.com/sections/${sectionId}.json`).then(res => {
      return res.json();
    });
    console.log(data)
    if (data != null) {
      let contactKey = data.contactKey
      let text = data.text
      setSectionText(text)
      setSectionContactKey(contactKey)
    } else {
      setSectionText('Chargement...')
      setSectionContactKey('16d8f168-c5cb-4b0d-9c90-facdaaa6df9d')
    }
  }
  useEffect(() => {
    setSectionId(match.params.sectionId)
  }, [match.params.sectionId])

  useEffect(() => {
    splitbee.track("visit page", {
      page: sectionId
    })
    fetchData(sectionId).then(setIsLoaded(true))
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [sectionId]);
  return (
    <>
      <IndexNavbar color="info" />
      <div className="wrapper">
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-justify">
                <h3 className="title">Section {getSectionName(sectionId)}</h3>
                <Card>
                  <CardBody style={{textAlign:'left'}}>
                    {isLoaded ?
                      sectionText && <p dangerouslySetInnerHTML={{ __html: sectionText }} />
                      :
                      <Spinner type="grow" color="primary" />
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Une question?</h2>
            <p className="description">Pour toute information contactez le correspondant de la section avec le formulaire ci-dessous</p>
            <p className="description">Ou envoyez directement un mail à l'adresse {sectionId}@millenium-marles.fr</p>
            
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nom / Prénom"
                    type="text"
                    value={formName}
                    onChange={data => setFormName(data.target.value)}
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    value={formEmail}
                    onChange={data => setFormEmail(data.target.value)}
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Votre message"
                    rows="4"
                    type="textarea"
                    value={formMsg}
                    onChange={data => setFormMsg(data.target.value)}
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault()
                      toast.promise(sendMail(), {
                        loading: 'Envoie en cours',
                        success: 'Message envoyé',
                        error: 'Une erreur est survenue',
                      });
                      
                    }}
                    size="lg"
                  >
                    Envoyer
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default Sections;
